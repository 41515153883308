<template>
<div style="height: 100%;">
  <router-view></router-view>
</div>
</template>
<script lang="ts" setup>


</script>
<style>
</style>
   